import { Scroller, ScrollerProvider } from '@graphcommerce/framer-scroller'
import { i18n } from '@lingui/core'
import { Box, Link, Typography } from '@mui/material'
import { toArray } from 'lodash'
import { useRouter } from 'next/router'

export type SearchListCategoriesProps = {
  items?: any
  // total?: string | number
  search?: string
}

type LinkItemProps = {
  label: string
  count: string | number
  value: string
  search?: string
}

const LinkItem = (props: LinkItemProps) => {
  const router = useRouter()

  const query = toArray(router.query?.url)
  const queryMain = query.filter((elem) => elem !== 'q')

  // const searchParam = queryMain?.[0] || ''
  const categoryCurrent = queryMain?.[2] || ''

  let isActive = categoryCurrent == props.value ? true : false
  if (props.value == '' && categoryCurrent == '') {
    isActive = true
  }

  const categoryParam =
    props.search + (props.value != '' ? '/q/temp_systembrand/' + props.value : '')

  return (
    <Link
      color='inherit'
      underline={'none'}
      onClick={() => router.replace('/search/' + categoryParam)}
      sx={(theme) => ({
        color: theme.palette.filtoria.grey.dark,
        whiteSpace: 'nowrap',
        marginRight: theme.spacing(3),
        cursor: 'pointer',
      })}
    >
      <Box
        component={'span'}
        sx={(theme) => ({
          borderWidth: 0,
          borderBottomWidth: isActive ? 2 : 0,
          borderColor: theme.palette.filtoria.primary.dark,
          borderStyle: 'solid',
          boxSizing: 'border-box',
          lineHeight: 2.5,
        })}
      >
        <Typography
          variant='body2'
          component='span'
          sx={(theme) => ({
            color: isActive
              ? theme.palette.filtoria.grey.darkest
              : theme.palette.filtoria.grey.dark,
          })}
        >
          {props.label}{' '}
        </Typography>
        {Number(props.count) > 0 && (
          <Typography variant='body2' component='span'>
            {'(' + props.count + ')'}
          </Typography>
        )}
      </Box>
    </Link>
  )
}

export function SearchListCategories(props: SearchListCategoriesProps) {
  const { items, search } = props
  return (
    <>
      <Box className='product-cateries-list'>
        <ScrollerProvider scrollSnapAlign='end'>
          <Scroller
            hideScrollbar
            sx={(theme) => ({
              '& .banner-text-on-image': {
                width: '100vw',
              },
            })}
          >
            <LinkItem label={i18n._(/* i18n */ 'All')} count={0} value='' search={search} />
            {items?.map((item, index) => {
              return (
                <LinkItem
                  key={index}
                  label={item.label}
                  count={item.count}
                  value={item.value}
                  search={search}
                />
              )
            })}
          </Scroller>
        </ScrollerProvider>
      </Box>
    </>
  )
}
