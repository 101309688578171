import { WaitForQueries } from '@graphcommerce/ecommerce-ui'
import {
  extendableComponent,
  DesktopHeaderBadge,
  IconSvg,
  useScrollY,
  useFabSize,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { alpha, Fab, FabProps, styled, useTheme, Box, SxProps, Theme } from '@mui/material'
import { m, useTransform } from 'framer-motion'
import React from 'react'
import { useCartQuery } from '@graphcommerce/magento-cart/hooks/useCartQuery'
import { CartFabDocument } from '@graphcommerce/magento-cart/components/CartFab/CartFab.gql'
import { CartTotalQuantityFragment } from '@graphcommerce/magento-cart/components/CartFab/CartTotalQuantity.gql'
import CartIcon from '../Common/Icons/Cart/cart-icon.svg'

export type CartFabProps = {
  icon?: React.ReactNode
  sx?: SxProps<Theme>
} & Pick<FabProps, 'color' | 'size' | 'variant'>

type CartFabContentProps = CartFabProps & CartTotalQuantityFragment

const MotionDiv = styled(m.div)({})

const MotionFab = m(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.forwardRef<any, Omit<FabProps, 'style' | 'onDrag'>>((props, ref) => (
    <Fab {...props} ref={ref} />
  )),
)

const { classes } = extendableComponent('CartFab', ['root', 'cart', 'shadow'] as const)

function CartFabContent(props: CartFabContentProps) {
  const { total_quantity, icon, sx = [], ...fabProps } = props

  const theme2 = useTheme()
  const scrollY = useScrollY()
  const opacity = useTransform(scrollY, [50, 60], [0, 1])

  const paper0 = alpha(theme2.palette.background.paper, 0)
  const paper1 = alpha(theme2.palette.background.paper, 1)
  const backgroundColor = useTransform(scrollY, [0, 10], [paper0, paper1])

  const cartIcon = icon ?? (
    <IconSvg style={{ fontSize: '24px', width: '24px' }} src={CartIcon} size='inherit' />
  )
  const fabIconSize = useFabSize('responsive')

  return (
    <Box
      className={classes.root}
      sx={[
        {
          position: 'relative',
          height: fabIconSize,
          '& .MuiFab-root': {
            width: '24px!important',
          },
          '&.CartFab-root': {
            width: '40px',
            textAlign: 'center',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <MotionFab
        href='/cart'
        className={classes.cart}
        aria-label={i18n._(/* i18n */ 'Cart')}
        color='inherit'
        size='responsive'
        style={{ backgroundColor }}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            backgroundColor: `${theme.palette.background.paper} !important`,
          },
        })}
        {...fabProps}
      >
        <DesktopHeaderBadge
          color='primary'
          overlap='circular'
          badgeContent={total_quantity}
          sx={(theme) => ({
            '& .MuiBadge-badge': {
              backgroundColor: theme.palette.filtoria.primary.light,
              color: theme.palette.filtoria.grey.darkest,
              padding: { xs: '1px 6px', md: '1px 6px' },
              lineHeight: '12px',
              fontWeight: '700',
              borderRadius: '20px',
              minWidth: 'unset',
              height: 'unset',
              top: '-4px',
              right: '4px',
            },
          })}
        >
          {cartIcon}
        </DesktopHeaderBadge>

        {/* <Box>
          <Box sx={{ position: 'absolute' }}>{total_quantity}</Box>
          {cartIcon}
        </Box> */}
      </MotionFab>

      <MotionDiv
        className={classes.shadow}
        sx={(theme) => ({
          pointerEvents: 'none',
          borderRadius: '99em',
          position: 'absolute',
          height: '100%',
          width: '100%',
          top: 0,
          [theme.breakpoints.down('md')]: {
            opacity: '1 !important',
          },
        })}
        style={{ opacity }}
      />
    </Box>
  )
}

/**
 * We give CartFab a bit of special handling. We don't want to make requests for this component
 * whilly nilly. We've imposed some limitations:
 *
 * We use useCartQuery that means that this will only execute when there is a cartId.
 *
 * We use fetchPolicy 'cache-only' so that when the cart comes into existence it will not
 * immediately start fetching. Why? There is a time between creating a cart and adding the first
 * product to the cart. This would mean that it would immediately start executing this query.
 */
export function CartFabByNP(props: CartFabProps) {
  const cartQuery = useCartQuery(CartFabDocument, {
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'cache-first',
  })

  return (
    <WaitForQueries waitFor={cartQuery} fallback={<CartFabContent {...props} total_quantity={0} />}>
      <CartFabContent total_quantity={cartQuery.data?.cart?.total_quantity ?? 0} {...props} />
    </WaitForQueries>
  )
}
