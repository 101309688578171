// import { CartFab } from '@graphcommerce/magento-cart'
import { magentoMenuToNavigationMain } from '../../utils/magentoMenuToNavigationMain'
import { CustomerFab, CustomerMenuFabItem } from '@graphcommerce/magento-customer'
import {
  DesktopNavActions,
  DesktopNavBar,
  LayoutDefault,
  LayoutDefaultProps,
  MenuFabSecondaryItem,
  IconSvg,
  DesktopNavItem,
  iconChevronDown,
  NavigationProvider,
  useNavigationSelection,
  useMemoDeep,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, Container, Divider, SxProps, Theme } from '@mui/material'
import { useRouter } from 'next/router'
import { Footer } from './Footer'
import { LayoutQuery } from './Layout.gql'
import { Logo } from './Logo'
import { MobileNavActions } from './MobileNavActions'
import { NavigationOverlayByNP } from './Navigation/NavigationOverlayByNP'
import iconCustomerService from '../Common/Icons/Service/icon.svg'
import iconUser from '../Common/Icons/User/user-icon.svg'
import iconInfo from '../Common/Icons/Info/icon.svg'
import { CartFabByNP } from '../CartFabByNP'
import ContainerAdapter from './ContainerAdapter'
// import useMediaQuery from '../../utils/useMediaQuery'
import { isMobile } from '../../utils'
import React, { useEffect, useState } from 'react'
import { SearchForm } from '@graphcommerce/magento-search'
import HeaderSearchForm from '../Common/SearchForm/HeaderSearchForm'
import { ACCOUNT_LOG_IN_URL } from '../../common/constants/account-link'

export type LayoutNavigationProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function LayoutNavigation(props: LayoutNavigationProps) {
  const [mobile, setMobile] = useState(false)
  const [client, setClient] = useState(false)
  useEffect(() => {
    if (isMobile()) {
      setMobile(true)
    }
    setClient(true)
  }, [])

  const { footer, menu, children, ...uiProps } = props
  // const isMobile = useMediaQuery('(max-width: 1023px)')

  const selection = useNavigationSelection()
  const router = useRouter()
  // const getArticleUrl = UseArticleUrl()

  const overlaySx: SxProps<Theme> = (theme) => ({
    '& .NavigationItem-li:not(.column1)': {
      '& .NavigationItem-item': {
        borderBottom: '1px solid ' + theme.palette.filtoria.grey.lighter,
      },
    },
    '& .NavigationItem-li': {
      '& .MuiListItemButton-root:not(.MenuFabSecondaryItem-root) .IconSvg-root': {
        color: theme.palette.filtoria.primary.dark,
      },
    },
  })

  return (
    <>
      <NavigationProvider
        selection={selection}
        animationDuration={0.3}
        items={useMemoDeep(
          () => [
            // { id: 'home', name: <Trans id='Home' />, href: '/' },
            ...magentoMenuToNavigationMain(menu, true),
            // {
            //   id: 'blog',
            //   name: i18n._(/* i18n */ 'Blog'),
            //   href: getArticleUrl,
            // },
            <Divider
              sx={(theme) => ({
                my: theme.spacings.xs,
                '& .MuiDivider-root': {
                  xs: {
                    marginLeft: '-16px',
                    marginRight: '-16px',
                  },
                  md: {
                    marginLeft: '-48px',
                    marginRight: '-48px',
                  },
                },
              })}
            />,
            <MenuFabSecondaryItem
              icon={<IconSvg src={iconInfo} sx={{ fontSize: '1.5rem' }} size='inherit' />}
              key='About Filtoria'
              href='/kundeservice/omoss'
              sx={() => ({
                paddingTop: '8px',
                paddingBottom: '8px',
                '& .MuiListItemText-root': {
                  marginTop: 0,
                  marginBottom: 0,
                },
                '& .MuiTypography-root': {
                  fontSize: '1rem',
                },
              })}
            >
              <Trans id='About Filtoria' />
            </MenuFabSecondaryItem>,
            <CustomerMenuFabItem
              icon={<IconSvg src={iconUser} sx={{ fontSize: '1.5rem' }} size='inherit' />}
              key='account'
              guestHref={ACCOUNT_LOG_IN_URL}
              authHref='/account'
              sx={() => ({
                paddingTop: '8px',
                paddingBottom: '8px',
                '& .MuiListItemText-root': {
                  marginTop: 0,
                  marginBottom: 0,
                },
                '& .MuiTypography-root': {
                  fontSize: '1rem',
                },
              })}
            >
              <Trans id='Account' />
            </CustomerMenuFabItem>,
            <MenuFabSecondaryItem
              sx={() => ({
                paddingTop: '8px',
                paddingBottom: '8px',
                '& .MuiListItemText-root': {
                  marginTop: 0,
                  marginBottom: 0,
                },
                '& .MuiTypography-root': {
                  fontSize: '1rem',
                },
              })}
              key='service'
              icon={
                <IconSvg src={iconCustomerService} sx={{ fontSize: '1.5rem' }} size='inherit' />
              }
              href='/service'
            >
              <Trans id='Customer Service' />
            </MenuFabSecondaryItem>,
          ],
          [menu],
        )}
      >
        {mobile ? (
          <>
            <Container className='parent-category-overlay-wrap'>
              {/* Main menu - click from hamburger button */}
              <NavigationOverlayByNP
                stretchColumns={false}
                variantSm='left'
                sizeSm='full'
                justifySm='start'
                itemWidthSm='90vw'
                variantMd='left'
                sizeMd='full'
                justifyMd='start'
                itemWidthMd='314px'
                mouseEvent='hover'
                itemPadding='md'
                isParent={true}
                sx={overlaySx}
              />
            </Container>
            {/* Main menu - click from homepage - category button */}
            <Container className='product-category-menu-overlay-wrap'>
              <NavigationOverlayByNP
                stretchColumns={false}
                variantSm='bottom'
                sizeSm='full'
                justifySm='start'
                itemWidthSm='100vw'
                variantMd='left'
                sizeMd='full'
                justifyMd='start'
                itemWidthMd='304px'
                mouseEvent='hover'
                itemPadding='md'
                isParent={false}
                sx={overlaySx}
              />
            </Container>
          </>
        ) : (
          <Container className='product-category-menu-overlay-wrap'>
            <NavigationOverlayByNP
              stretchColumns={false}
              variantSm='left'
              sizeSm='full'
              justifySm='start'
              itemWidthSm='90vw'
              variantMd='left'
              sizeMd='full'
              justifyMd='start'
              itemWidthMd='314px'
              mouseEvent='click'
              itemPadding='md'
              sx={overlaySx}
            />
          </Container>
        )}
      </NavigationProvider>

      <LayoutDefault
        {...uiProps}
        className='layout-navigation'
        noSticky={router.asPath.split('?')[0] === '/'}
        header={
          <ContainerAdapter>
            <div className='header-inner'>
              <Box
                className='logo-wrap'
                sx={(theme) => ({
                  display: 'none',
                  [theme.breakpoints.up('md')]: { display: 'block' },
                })}
              >
                <Logo />
              </Box>

              <DesktopNavBar>
                {/*
                {menu?.items?.[0]?.children?.slice(0, 2).map((item) => (
                  <DesktopNavItem
                    key={item?.uid}
                    href={`/${item?.url_path}`}
                    variant='h3'
                    sx={{ fontVariationSettings: "'wght' 500" }}
                  >
                    {item?.name}
                  </DesktopNavItem>
                ))}
                */}

                <DesktopNavItem
                  onClick={() => selection.set([menu?.items?.[0]?.uid || ''])}
                  variant='h3'
                  sx={{ fontVariationSettings: "'wght' 500" }}
                >
                  {menu?.items?.[0]?.name}
                  <IconSvg src={iconChevronDown} />
                </DesktopNavItem>

                <DesktopNavItem
                  href='/kundeservice/omoss'
                  variant='h3'
                  sx={{ fontVariationSettings: "'wght' 500" }}
                >
                  <Trans id='About Filtoria' />
                </DesktopNavItem>

                <DesktopNavItem
                  href='/service'
                  variant='h3'
                  sx={{ fontVariationSettings: "'wght' 500" }}
                >
                  <Trans id='Customer Service' />
                </DesktopNavItem>
              </DesktopNavBar>

              <DesktopNavActions>
                {client && typeof window !== 'undefined' &&
                  window.matchMedia('(min-width: 1200px)').matches && <HeaderSearchForm />}
                <CustomerFab
                  sx={() => ({
                    '&.MuiButtonBase-root': {
                      width: '40px',
                      textAlign: 'center',
                    },
                    '& .MuiBadge-root': {
                      fontSize: '24px',
                    },
                  })}
                  guestHref={ACCOUNT_LOG_IN_URL}
                  authHref='/account'
                />
                {/*
                <Fab
                  href='/service'
                  aria-label={i18n._('Account')}
                  size='large'
                  color='inherit'
                >
                  <IconSvg src={iconCustomerService} sx={{ fontSize: '1.5rem' }} size='inherit' />
                </Fab>
                */}
                <CartFabByNP />
                {/* The placeholder exists because the CartFab is sticky but we want to reserve the space for the <CartFab /> */}
                {/*
                <PlaceholderFab />
                */}
              </DesktopNavActions>

              <MobileNavActions
                handleClick={() => {
                  //Reset emty to hash
                  router.push('#main-menu')
                  selection.set([])
                }}
              />
              <Box
                id='handleOpenMenu'
                onClick={() => {
                  const paths = document.getElementById('handleOpenMenu')?.getAttribute('paths')
                  const itemPath = paths?.split(',') || []
                  selection.set(itemPath)
                }}
              ></Box>
            </div>
            {typeof window !== 'undefined' &&
              window.matchMedia('(max-width: 1199px)').matches &&
              window.matchMedia('(min-width: 1022px)').matches && (
                <Box
                  className='search-form-wrap'
                  // sx={rowBottomSx}
                  onClick={() => {
                    router.push('/search')
                  }}
                >
                  <SearchForm
                    totalResults={0}
                    search=''
                    textFieldProps={{
                      size: 'small',
                      label: i18n._(/* i18n */ 'Find your filter...'),
                    }}
                    sx={{ display: 'block' }}
                  />
                </Box>
              )}
          </ContainerAdapter>
        }
        footer={client ? <Footer footer={footer} /> : null}
        cartFab={<></>}
        menuFab={<></>}
        sx={(theme) => ({
          '& .LayoutDefault-header': {
            position: 'relative', //sticky
            top: 0,
            backgroundColor: theme.palette.primary.contrastText,
            height: 'unset',
          },
        })}
      >
        {children}
      </LayoutDefault>
    </>
  )
}
