import * as React from 'react'
import { Container } from '@mui/material'
// import useMediaQuery from '../../utils/useMediaQuery'
import { isMobile } from '../../utils'
import { useState, useEffect } from 'react'

const ContainerAdapter = (props: { children?: React.ReactNode; className?: string }) => {
  // const isMobile = useMediaQuery('(max-width: 1023px)')
  const { children, className, ...rest } = props
  const [maxWidth, setMaxWidth] = useState<any>('lg')

  useEffect(() => {
    const mw = isMobile() ? 'lg' : 'md'
    if (maxWidth !== mw) {
      setMaxWidth(mw)
    }
  }, [])

  return (
    <Container {...rest} className={`container ${className || ''}`} maxWidth={maxWidth}>
      {children}
    </Container>
  )

  // if (isMobile()) {
  //   return (
  //     <Container {...rest} className={`container ${className || ''}`} maxWidth={'lg'}>
  //       {children}
  //     </Container>
  //   )
  // }

  // return (
  //   <Container {...rest} className={`container ${className || ''}`} maxWidth={'md'}>
  //     {children}
  //   </Container>
  // )
}

export default ContainerAdapter
