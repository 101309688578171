import { Box, Container, Stack, SxProps, Theme, useScrollTrigger } from '@mui/material'
import { Logo } from './Logo'
import { CustomerFab } from '@graphcommerce/magento-customer'
import { IconSvg, iconMenu, iconSearch } from '@graphcommerce/next-ui'
import { SearchForm } from '@graphcommerce/magento-search'
import { i18n } from '@lingui/core'
import { useEffect, useRef, useState } from 'react'
import { CartFabByNP } from '../CartFabByNP'
import { useRouter } from 'next/router'
import { isMobile, isTablet } from '../../utils'
import { ACCOUNT_LOG_IN_URL } from '../../common/constants/account-link'

const containerSx: SxProps<Theme> = (theme) => ({
  width: '100%',
  display: 'none',
  padding: 0,
  [theme.breakpoints.down('md')]: { display: 'block' },
  boxSizing: 'border-box',
  // boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
})

const rowTopSx: SxProps<Theme> = (theme) => ({
  marginTop: theme.spacing(1),
  // paddingLeft: theme.spacing(1),
  // paddingRight: theme.spacing(1),
  '& .CustomerFab-root': {
    width: theme.spacing(3.5),
    height: theme.spacing(6),
  },
  '& .CartFab-root': {
    width: theme.spacing(3.5),
    height: theme.spacing(6),
    '& .CartFab-cart': { width: theme.spacing(4), height: theme.spacing(6) },
    '& .CartFab-shadow': { display: 'none' },
  },
})

const rowBottomSx: SxProps<Theme> = (theme) => ({
  // paddingLeft: theme.spacing(1),
  // paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(1),
})

export function MobileNavActions(props: any) {
  const { handleClick } = props
  const [showSearch, setShowSeach] = useState(false)
  const [showMenuAction, setShowMenuAction] = useState(false)
  const targetRef = useRef<HTMLDivElement | null>(null)
  const scrollTrigger = useScrollTrigger({
    threshold: 50,
    disableHysteresis: true,
    // target: targetRef.current ?? undefined,
  })

  // const isTablet = isTablet()
  const router = useRouter()

  const handleClickSearch = () => {
    setShowSeach(!showSearch)
  }

  useEffect(() => {
    setShowSeach(scrollTrigger)
  }, [scrollTrigger])

  useEffect(() => {
    if (isTablet()) {
      setShowMenuAction(true)
    }
  }, [isTablet()])

  if (!showMenuAction) {
    return <></>
  }

  return (
    <div style={{ width: '100%' }}>
      <Box className='nav-mobile-actions' ref={targetRef}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={rowTopSx}>
          <Box sx={{ width: '18%' }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Box onClick={() => handleClick()}>
                <IconSvg
                  src={iconMenu}
                  size='large'
                  sx={{ display: 'block', width: '32px', height: '32px' }}
                />
              </Box>
              <Box
                onClick={() => handleClickSearch()}
                sx={{ display: scrollTrigger ? 'block' : 'none' }}
              >
                <IconSvg src={iconSearch} size='large' sx={{ display: 'block' }} />
              </Box>
            </Stack>
          </Box>
          <Box sx={{ width: '60%', textAlign: 'center', '& img': { minHeight: '26px' } }}>
            <Logo />
          </Box>
          <Box sx={{ width: '18%' }}>
            <Stack
              direction='row'
              justifyContent='flex-end'
              gap={'12px'}
              sx={{
                '& .MuiBadge-root': {
                  width: '24px',
                  height: '24px',
                },
              }}
            >
              <CustomerFab guestHref={ACCOUNT_LOG_IN_URL} authHref='/account' />
              <CartFabByNP />
            </Stack>
          </Box>
        </Stack>
        <Box
          className='search-form-wrap'
          sx={rowBottomSx}
          onClick={() => {
            router.push('/search')
          }}
        >
          <SearchForm
            totalResults={0}
            search=''
            textFieldProps={{
              // autoFocus: true,
              size: 'small',
              label: i18n._(/* i18n */ 'Find your filter...'),
            }}
            sx={{ display: showSearch ? 'none' : 'block' }}
          />
        </Box>
      </Box>
    </div>
  )
}
