import { ProductFiltersDocument } from '@graphcommerce/magento-product'
import { ProductListDocument } from '@graphcommerce/magento-product/components/ProductList/ProductList.gql'
import { useQuery } from '@graphcommerce/graphql'
import { Trans } from '@lingui/react'
import { Typography, Popper, Link, IconButton } from '@mui/material'
import { SearchListItems } from '../../Search/SearchListItems'
// import { useEffect, useState, useRef } from 'react'
import * as React from 'react'
import CloseIcon from '../../Common/Icons/Close'
import LoadingSpinner from '../../Layout/LoadingSpinner'
import { SearchListCategories } from '../../Search/SearchListCategories'
import { get } from 'lodash'
import { CategorySearchDocument } from '@graphcommerce/magento-search'

import { CategorySearchResult } from './CategorySearchResult'

const SearchResultPopover = ({
  search,
  anchorEl,
  handleClose,
}: {
  search: string
  anchorEl: any
  handleClose: () => void
}) => {
  // const productListParams = parseParams(`search/${search}`, [], {}, search)

  const { loading, error, data } = useQuery(ProductListDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { pageSize: 12, search },
  })

  const categoryQuery = useQuery(CategorySearchDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { pageSize: 12, search },
  })

  const filters = useQuery(ProductFiltersDocument, {
    variables: { search },
  })

  const categories = categoryQuery?.data?.categories

  const products = data?.products

  const totalSearchResults = (categories?.items?.length ?? 0) + (products?.total_count ?? 0)

  const isOpen = !!anchorEl
  const id = isOpen ? 'simple-popper' : undefined
  const [showListCats, setShowListCats] = React.useState(false)

  const cats =
    filters?.data?.filters?.aggregations?.find(
      (filter) => filter?.attribute_code == 'temp_systembrand',
    ) ?? {}
  const catsCheck = get(cats, 'options')
  const catOptions = catsCheck !== undefined ? catsCheck : []
  const numberOfDisplay = 5

  return (
    <>
      <Popper
        // transition
        sx={{ zIndex: 1200 }}
        placement={'bottom'}
        className='header-search-result-popover'
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
      >
        <div className='popover-content'>
          {loading && <LoadingSpinner />}
          {!loading && products?.items && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {!showListCats && totalSearchResults > numberOfDisplay ? (
                  <Link
                    variant='body2'
                    // onClick={() => router.push(`/search/${search}`)}
                    onClick={() => setShowListCats(true)}
                    sx={(theme) => ({
                      color: theme.palette.filtoria.primary.dark,
                      cursor: 'pointer',
                      fontSize: '16px',
                    })}
                  >
                    <Trans id='See all {count} results' values={{ count: totalSearchResults }} />
                  </Link>
                ) : (
                  <div />
                )}
                {showListCats && search && (
                  <SearchListCategories
                    items={catOptions}
                    // total={totalSearchResults}
                    search={search}
                    // onClose={handleClose}
                  />
                )}
                <IconButton
                  className='close-btn'
                  size='small'
                  aria-label='close'
                  color='inherit'
                  onClick={handleClose}
                >
                  <CloseIcon size='medium' />
                </IconButton>
              </div>
              {!loading && totalSearchResults === 0 && (
                <div>
                  <Typography sx={{ fontSize: '1rem' }} align='center'>
                    <Trans id="We couldn't find any results for {term}" values={{ term: search }} />
                  </Typography>
                </div>
              )}
              {categories?.items && categories?.items.length > 0 && (
                <div className='category-search-result'>
                  {categories?.items?.map((category, index) => (
                    <CategorySearchResult
                      onClose={handleClose}
                      key={category?.url_path || index}
                      search={search}
                      {...category}
                    />
                  ))}
                </div>
              )}
              <SearchListItems
                items={
                  products?.items.length > numberOfDisplay && !showListCats
                    ? products?.items.slice(0, numberOfDisplay)
                    : products?.items
                }
                onClose={handleClose}
              />
            </>
          )}
        </div>
      </Popper>
    </>
  )
}

export default SearchResultPopover
