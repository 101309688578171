import {
  ProductListItemFragment,
  ProductListItemProps,
  useProductLink,
} from '@graphcommerce/magento-product'
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  styled,
} from '@mui/material'
import { Image } from '@graphcommerce/image'
import ChevronRightIcon from '../../Common/Icons/ChevronRight'
import { Trans } from '@lingui/react'
import React from 'react'
import ProductSku from '../../Catalog/Product/ProductSku'
import { useRouter } from 'next/router'

export type SearchListItemsProps = {
  onClose?: () => void
  items?:
    | Array<(ProductListItemFragment & ProductListItemProps) | null | undefined>
    | null
    | undefined
}

const StyledImage = styled(Image)({})

export function SearchListItems(props: SearchListItemsProps) {
  const { items, onClose } = props
  const router = useRouter()

  return (
    <List
      className='list-items product-search-result-wrap'
      dense
      sx={{ width: '100%', bgcolor: 'background.paper' }}
    >
      <Divider />
      {items?.map((item, index) => {
        const labelId = `checkbox-list-secondary-label`
        const productLink = useProductLink({
          __typename: item?.__typename || 'SimpleProduct',
          url_key: item?.url_key,
        })
        return (
          <ListItem
            className='search-list-item list-item product-item-wrap'
            key={index}
            secondaryAction={
              <IconButton
                className='right-icon-inner'
                aria-label='go'
                sx={(theme) => ({
                  color: theme.palette.filtoria.primary.dark,
                  padding: 0,
                  marginRight: theme.spacingRem(3),
                  '&:hover': {
                    background: 'none',
                  },
                })}
                size='large'
              >
                <ChevronRightIcon sx={{ fontSize: '24px' }} size='large' />
              </IconButton>
            }
            disableGutters
            divider={true}
            onClick={(e) => {
              e.preventDefault()
              router.push(productLink)
              if (onClose) {
                onClose()
              }
            }}
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
              },
            }}
          >
            <ListItemButton
              disableGutters
              href={productLink}
              sx={{
                paddingTop: '1px',
                paddingBottom: '1px',
                paddingRight: '30px !important',
                '&:hover': {
                  background: 'none',
                },
              }}
            >
              <Box className='product-image-inner' sx={{ flexBasis: '66px' }}>
                {item?.small_image ? (
                  <StyledImage
                    layout='fill'
                    width={3}
                    height={2}
                    dontReportWronglySizedImages={true}
                    src={item.small_image.url ?? ''}
                    alt={item.small_image.label ?? ''}
                    loading={'lazy'}
                    sx={{
                      objectFit: 'contain',
                      aspectRatio: `1.7`,
                    }}
                    loader={({ src, width }) => {
                      let w = width

                      if (width <= 828) {
                        w = 1200
                      }
                      return `/_next/image?url=${encodeURIComponent(src)}&w=${w}&q=${100}`
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      gridArea: `1 / 1 / 3 / 3`,
                      typography: 'caption',
                      display: 'flex',
                      textAlign: 'center',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'background.default',
                      userSelect: 'none',
                    }}
                  >
                    <Trans id='No Image' />
                  </Box>
                )}
              </Box>
              <ListItemText
                className='product-info'
                id={labelId}
                primary={<strong>{item?.name}</strong>}
                secondary={
                  <React.Fragment>
                    <Typography
                      className='system-brand'
                      variant='body2'
                      sx={(theme) => ({ color: theme.palette.filtoria.grey.darker })}
                    >
                      {item?.categories?.[0]?.name}
                    </Typography>
                    <ProductSku sku={item?.sku ?? ''} fontSize={[14, 14]} />
                  </React.Fragment>
                }
                sx={{ margin: '0 0 0 12px' }}
              />
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}
