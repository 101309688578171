import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { ContainerProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { RowColunmAutoItem } from './RowColunmLinkAutoItem'

export type RowColunmLinkAutoColLink = {
  title?: string
  url?: string
}

export type RowColunmLinkAutoCol = {
  title?: string
  links?: RowColunmLinkAutoColLink[]
}

export type RowColunmLinkAutoProps = {
  cols: RowColunmLinkAutoCol[]
} & Omit<ContainerProps, 'children'>

export function RowColunmLinkAuto(props: RowColunmLinkAutoProps) {
  const { cols, sx, ...containerProps } = props

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Grid
        maxWidth={'lg'}
        container
        columns={cols.length}
        rowSpacing={1}
        columnSpacing={{ xs: 0, sm: 2, md: 3 }}
        sx={{
          margin: '0 auto !important',
          boxSizing: 'border-box',
        }}
      >
        {cols.map((item, index) => (
          <RowColunmAutoItem key={'col-' + index} item={item} col={cols.length} mobile={matches} />
        ))}
      </Grid>
    </Box>
  )
}
