// import useMediaQuery from './useMediaQuery'

export const getHygraphImageUrl = (url: string, w?: number, h?: number) => {
  if (!url.includes('https://media.graphassets.com/')) {
    return url
  }
  const codes = url.split('/')
  if (codes.length < 1) {
    return url
  }
  const code = codes[codes.length - 1]
  if (w) {
    return `https://media.graphassets.com/output=format:webp/resize=width:${w},height:${h}/${code}`
  }
  return `https://media.graphassets.com/output=format:webp/quality=value:100/${code}`
}

export const isMobile = () => {
  // return useMediaQuery('(max-width: 1023px)')
  if (typeof window !== 'undefined') {
    return window.innerWidth < 768
  }

  return true
}

export const isTablet = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < 991
  }

  return true
}
