import { ContainerProps } from '@mui/material'
import { RowLinkFiveFragment } from './RowLinkFive.gql'
import { RowColunmLinkAuto } from '../../Common/RowColunmLinkAuto/RowColumnLinkAuto'

export type RowLinkFiveProps = {
  cols?: RowLinkFiveFragment[]
} & Omit<ContainerProps, 'children'>

export function RowLinkFive(props: RowLinkFiveProps) {
  const { cols, sx } = props

  return (
    <>
      {cols?.map((row, index) => {
        const items = [
          { title: row.titleOne || '', links: row.colOne },
          { title: row.titleTwo || '', links: row.colTwo },
          { title: row.titleThree || '', links: row.colThree },
          { title: row.titleFour || '', links: row.colFour },
        ]
        return (
          <RowColunmLinkAuto cols={items} key={index} sx={[...(Array.isArray(sx) ? sx : [sx])]} />
        )
      })}
    </>
  )
}
