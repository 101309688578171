import { Box, IconButton, Link, List, ListItem, Typography } from '@mui/material'
import ChevronRightIcon from '../../Common/Icons/ChevronRight'
import { PageLinkFragment } from '../../../graphql/PageLink.gql'

export type FooterMobilePageLinksProps = {
  links?: PageLinkFragment[]
}

export function FooterMobilePageLinks(props: FooterMobilePageLinksProps) {
  const { links } = props
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.filtoria.primary.darkest,
        color: theme.palette.primary.contrastText,
        paddingX: 0,
        display: 'none',
        [theme.breakpoints.down('md')]: { display: 'block' },
      })}
    >
      <List
        component={'div'}
        sx={(theme) => ({
          width: '100%',
          backgroundColor: theme.palette.filtoria.primary.darkest,
          color: theme.palette.primary.contrastText,
          paddingX: 0,
        })}
      >
        {links &&
          links?.map((item, index) => (
            <ListItem
              key={index}
              component={Link}
              href={item.url}
              disableGutters
              secondaryAction={
                <IconButton
                  aria-label='go'
                  sx={(theme) => ({ color: theme.palette.primary.contrastText, padding: 0 })}
                  size='medium'
                >
                  <ChevronRightIcon size='medium' />
                </IconButton>
              }
              sx={(theme) => ({
                paddingY: theme.spacing(3),
                '&:not(:last-child)': { borderBottom: '1px solid #fff' },
              })}
            >
              <Typography
                variant='body1'
                sx={(theme) => ({ color: theme.palette.primary.contrastText, fontWeight: '700' })}
              >
                {item.title}
              </Typography>
            </ListItem>
          ))}
      </List>
    </Box>
  )
}
