import { IconSvg } from '@graphcommerce/next-ui'
import { TextFieldElement } from '@graphcommerce/ecommerce-ui'
import { iconClose, iconSearch } from '@graphcommerce/next-ui'
import { useForm, useFormAutoSubmit } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { Box, IconButton } from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import * as React from 'react'
import SearchResultPopoverElastic from './SearchResultPopoverElastic'
import SearchResultPopover from './SearchResultPopover'
import { ClickAwayListener } from '@mui/base'

const HeaderSearchForm = () => {
  const handleSubmitSeach = (e: any) => {
    if (e.key == 'Enter') {
      setSearch(e.target.value)
      // document.getElementById('form-search-field')?.blur()
      searchInputElement.current?.focus()
    }
  }
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const elRef = React.useRef(null)
  const useElastic = true

  const handleClose = () => {
    setAnchorEl(null)
    setSearch('')

    setSearching(false)
    reset({ search: '' })
  }

  const searchInputElement = useRef<HTMLInputElement>(null)

  // const [totalResults, setTotalResults] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [searching, setSearching] = useState<boolean>(false)
  const form = useForm({ mode: 'onChange', defaultValues: { search } })
  const { handleSubmit, formState, reset, watch, getValues, control } = form

  useEffect(() => {
    //searchInputElement.current?.focus()
  }, [])

  useEffect(() => {
    if (search) {
      setSearching(true)
    } else {
      setSearching(false)
    }
  }, [search])

  const submit = handleSubmit((formData) => {
    setSearch(formData.search)

    setAnchorEl(elRef.current)
    reset(getValues())
    searchInputElement.current?.focus()
  })
  useFormAutoSubmit({ form, submit })

  const handleReset = () => {
    setSearch('')
    setSearching(false)
    reset({ search: '' })
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    //router.replace(`/${urlHandle}`)
  }

  const endAdornment = !watch('search') ? (
    <IconButton size='small' aria-label={i18n._(/* i18n */ 'Search')}>
      <IconSvg src={iconSearch} />
    </IconButton>
  ) : (
    <>
      <IconButton
        onClick={handleReset}
        size='small'
        sx={(theme) => ({
          color: theme.palette.filtoria.grey.darkest,
          backgroundColor: theme.palette.filtoria.grey.lighter,
          fontSize: '12px',
          padding: '3px',
          marginRight: theme.spacing(1),
          position: 'relative',
        })}
      >
        <IconSvg src={iconClose} />
      </IconButton>
      <Box
        sx={(theme) => ({
          height: '20px',
          width: '1px',
          background: '#D8D6D4',
        })}
      >
        {' '}
      </Box>
      <IconButton
        sx={{
          marginLeft: '4px',
          padding: 0,
        }}
        size='small'
        aria-label={i18n._(/* i18n */ 'Search')}
      >
        <IconSvg src={iconSearch} />
      </IconButton>
    </>
  )

  return (
    <Box className={`header-search-form ${searching && search.length > 0 ? 'searching' : ''}`}>
      <Box className='header-search-backdrop'></Box>
      <ClickAwayListener
        onClickAway={() => {
          if (search) {
            setSearch('')
          }
          setSearching(false)
        }}
      >
        <Box
          ref={elRef}
          className={'header-search-inner'}
          component='form'
          noValidate
          onSubmit={submit}
          onChange={() => {
            if (watch('search') === '') {
            }
          }}
        >
          <TextFieldElement
            variant='outlined'
            type='text'
            name='search'
            control={control}
            defaultValue={search}
            error={formState.isSubmitted && !!formState.errors.search}
            helperText={formState.isSubmitted && formState.errors.search?.message}
            InputProps={{ endAdornment }}
            inputRef={searchInputElement}
            onFocus={() => {
              setSearching(true)
            }}
            autoComplete={'off'}
            onBlur={() => {
              setSearching(false)
            }}
            sx={{
              '&.MuiFormControl-root': {
                width: '100%',
              },
              '& .MuiInputBase-root': {
                borderRadius: '30px',
                color: '#575757',
                width: '100%',
                overflow: 'hidden',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: search ? '2.5px' : '1px',
              },
            }}
            id={'form-search-field'}
            // autoFocus: true
            size={'small'}
            placeholder={i18n._(/* i18n */ 'Find your filter...')}
            onKeyDown={(e) => {
              handleSubmitSeach(e)
            }}
            label={null}
          />
          {search && anchorEl ? (
            useElastic ? (
              <SearchResultPopoverElastic
                handleClose={handleClose}
                anchorEl={anchorEl}
                search={search}
              />
            ) : (
              <SearchResultPopover handleClose={handleClose} anchorEl={anchorEl} search={search} />
            )
          ) : (
            <Box></Box>
          )}
        </Box>
      </ClickAwayListener>
    </Box>
  )
}

export default HeaderSearchForm
