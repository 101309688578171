import { ProductFiltersDocument } from '@graphcommerce/magento-product'
import { useQuery } from '@graphcommerce/graphql'
import { Trans } from '@lingui/react'
import { Typography, Popper, Link, IconButton } from '@mui/material'
import { SearchListItems } from '../../Search/SearchListItems'
import * as React from 'react'
import CloseIcon from '../Icons/Close'
import LoadingSpinner from '../../Layout/LoadingSpinner'
import { SearchListCategories } from '../../Search/SearchListCategories'
import { get } from 'lodash'

import { CategorySearchResult } from './CategorySearchResult'

const SearchResultPopover = ({
  search,
  anchorEl,
  handleClose,
}: {
  search: string
  anchorEl: any
  handleClose: () => void
}) => {

  const filters = useQuery(ProductFiltersDocument, {
    variables: { search },
  })

  const [dataApi, setDataApi] = React.useState<any>([])
  const [loadingApi, setLoadingApi] = React.useState(false)
  const [errorApi, setErrorApi] = React.useState<Error | null>(null)

  React.useEffect(() => {
    if (search) {
      const fetchData = async () => {
        setLoadingApi(true)
        setErrorApi(null)

        try {
          const response = await fetch(`/api/search?q=${search}`)
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          const result = await response.json()
          const productsAPI = result?.products?.hits
          const categoriesApi = result?.categories?.hits?.map((item) => {
            return {
              url_path: item.url,
              name: item.name,
              breadcrumbs: item.breadcrumbs,
            }
          })
          setDataApi({ products: productsAPI, categories: categoriesApi })
        } catch (error) {
          setErrorApi(error as Error)
        } finally {
          setLoadingApi(false)
        }
      }

      fetchData()
    }
  }, [search])

  const totalSearchResults = (dataApi?.categories?.length ?? 0) + (dataApi?.products?.length ?? 0)

  const isOpen = !!anchorEl
  const id = isOpen ? 'simple-popper' : undefined
  const [showListCats, setShowListCats] = React.useState(false)

  let catOptions = []
  if (showListCats) {
    const cats =
      filters?.data?.filters?.aggregations?.find(
        (filter) => filter?.attribute_code == 'temp_systembrand',
      ) ?? {}
    const catsCheck = get(cats, 'options')
    catOptions = catsCheck !== undefined ? catsCheck : []
  }

  const numberOfDisplay = 5

  return (
    <>
      <Popper
        // transition
        sx={{ zIndex: 1200 }}
        placement={'bottom'}
        className='header-search-result-popover'
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
      >
        <div className='popover-content'>
          {loadingApi && <LoadingSpinner />}
          {!loadingApi && dataApi && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {!showListCats && totalSearchResults > numberOfDisplay ? (
                  <Link
                    variant='body2'
                    // onClick={() => router.push(`/search/${search}`)}
                    onClick={() => setShowListCats(true)}
                    sx={(theme) => ({
                      color: theme.palette.filtoria.primary.dark,
                      cursor: 'pointer',
                      fontSize: '16px',
                    })}
                  >
                    <Trans id='See all {count} results' values={{ count: totalSearchResults }} />
                  </Link>
                ) : (
                  <div />
                )}
                {showListCats && search && (
                  <SearchListCategories
                    items={catOptions}
                    // total={totalSearchResults}
                    search={search}
                    // onClose={handleClose}
                  />
                )}
                <IconButton
                  className='close-btn'
                  size='small'
                  aria-label='close'
                  color='inherit'
                  onClick={handleClose}
                >
                  <CloseIcon size='medium' />
                </IconButton>
              </div>
              {!loadingApi && totalSearchResults === 0 && (
                <div>
                  <Typography sx={{ fontSize: '1rem' }} align='center'>
                    <Trans id="We couldn't find any results for {term}" values={{ term: search }} />
                  </Typography>
                </div>
              )}
              {dataApi?.categories && dataApi?.categories?.length > 0 && (
                <div className='category-search-result'>
                  {dataApi?.categories?.map((category, index) => (
                    <CategorySearchResult
                      onClose={handleClose}
                      key={category?.url_path || index}
                      search={search}
                      {...category}
                    />
                  ))}
                </div>
              )}
              <SearchListItems
                items={
                  dataApi?.products?.length > numberOfDisplay && !showListCats
                    ? dataApi?.products.slice(0, numberOfDisplay)
                    : dataApi?.products
                }
                onClose={handleClose}
              />
            </>
          )}
        </div>
      </Popper>
    </>
  )
}

export default SearchResultPopover
