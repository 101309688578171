import { Button, Stack } from '@mui/material'
import { useRouter } from 'next/router'
import { IButtonTexts } from '../globalTypes'
import { IButtonStyle, TBackgroundColor } from '../styledComponents'

export interface IButtonGroupProps extends IButtonTexts, IButtonStyle {
  callbacks: {
    accept: (all?: boolean) => void
    decline: () => void
  }
  toggleManageViewCallback: () => void
  showManagementView: boolean
  enableManagement?: boolean
  accentColor?: TBackgroundColor
  direction: 'column' | 'row'
}

const ButtonGroup = (props: IButtonGroupProps) => {
  const {
    callbacks,
    toggleManageViewCallback,
    showManagementView,
    // primaryButtonStyle,
    // secondaryButtonStyle,
    enableManagement,
    // accentColor,
    // direction,
  } = props

  const router = useRouter()

  const { acceptButtonText, declineButtonText, managementButtonText } = props

  // const DynPrimButton = ({ children, onClick }: IDynButtonProps) => (
  //   <BaseButton
  //     onClick={onClick}
  //     style={primaryButtonStyle}
  //     small={direction === 'row'}
  //     backgroundColor={accentColor}
  //   >
  //     {children}
  //   </BaseButton>
  // );
  // const DynSecButton = ({ children, onClick }: IDynButtonProps) => (
  //   <BaseButton onClick={onClick} style={secondaryButtonStyle} small={direction === 'row'}>
  //     {children}
  //   </BaseButton>
  // );

  const ManagementButton = () =>
    enableManagement ? (
      <FullButton
        style={'outline'}
        text={managementButtonText || ''}
        onClick={() => {
          toggleManageViewCallback()
          // router.push('#consent-management')
          history.pushState({}, '', '#consent-management')
        }}
      />
    ) : (
      <></>
    )

  const InfoViewButtonGroup = () => (
    <Stack sx={{ gap: '16px', marginTop: '24px' }} direction={'column'} alignItems={'flex-start'}>
      <FullButton
        style={'primary'}
        text={acceptButtonText || ''}
        onClick={() => callbacks.accept(true)}
      />
      <ManagementButton />
      <FullButton style={'outline'} text={declineButtonText || ''} onClick={callbacks.decline} />
    </Stack>
  )

  const ManageViewButtonGroup = () => (
    <Stack sx={{ gap: '16px', marginTop: '24px' }} direction={'column'} alignItems={'flex-start'}>
      <FullButton
        style={'primary'}
        text={'Lagre og godkjenn'}
        onClick={() => callbacks.accept(false)}
      />
      <FullButton style={''} text={'Kun nødvendige'} onClick={() => callbacks.accept(false)} />
    </Stack>
  )

  return showManagementView ? <ManageViewButtonGroup /> : <InfoViewButtonGroup />
}

const FullButton = ({
  text,
  onClick,
  style,
}: {
  text: string
  onClick: () => void
  style: string
}) => {
  if (style === 'primary') {
    return (
      <Button
        sx={{
          width: '100%',
          borderRadius: '30px',
          padidng: '10px 0',
        }}
        variant='contained'
        color='primary'
        size='large'
        onClick={onClick}
      >
        {text}
      </Button>
    )
  }

  return (
    <Button
      sx={{
        width: '100%',
        borderRadius: '30px',
        padding: '10px 0',
      }}
      variant='text'
      color='secondary'
      size='large'
      onClick={onClick}
    >
      {text}
    </Button>
  )
}

export default ButtonGroup
export { FullButton }
