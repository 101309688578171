export const HOME_PAGE_URL = '/'
export const ACCOUNT_PAGE_URL = '/account'
export const INIT_FILTER_URL = '/account/product/init-filter'
export const FILTER_SELECT_PRODUCT_URL = '/account/select-product'
export const KLANA_AUTHORIZE_URL = '/checkout/klarna/payment'
export const UPLOAD_IMAGE_REST_API = '/rest/V1/temp/image/uploadFiles'
export const UPGRADE_PREMIUM_URL = '/account/requisition/upgrade'
export const CART_PAGE_URL = '/cart'
export const ACCOUNT_MY_RETURN = '/account/returns'
export const ACCOUNT_SIGN_IN_URL = '/account/signin'
export const ACCOUNT_REGISTER = '/account/register'
export const VIPPS_LOGIN_CONFIRMATION = '/vipps/login/confirm'
export const EDIT_FILTER_SUBSCRIPTION_URL = '/account/product/init-filter-subscription'
export const EDIT_FILTER_SMS_URL = '/account/product/init-filter-sms'
export const ACCOUNT_LOG_IN_URL = '/account/login'
// export const ACCOUNT_LOG_IN_URL = '/account/signin'
