import {
  TextFieldElement,
  TextFieldElementProps,
  useForm,
  useFormAutoSubmit,
} from '@graphcommerce/ecommerce-ui'
// import { SearchFormProps } from '@graphcommerce/magento-search'
import { PluginProps } from '@graphcommerce/next-config'
import {
  FormRow,
  IconSvg,
  extendableComponent,
  iconClose,
  iconSearch,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, IconButton, SxProps, Theme } from '@mui/material'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

export type SearchFormProps = {
  totalResults?: number
  search?: string
  urlHandle?: string
  autoFocus?: boolean
  textFieldProps?: Omit<TextFieldElementProps<{ search: string }>, 'control' | 'name'>
  sx?: SxProps<Theme>
}

export const component = 'SearchForm'
export const exported = '@graphcommerce/magento-search/components/SearchForm/SearchForm'

const name = 'SearchForm' as const
const parts = ['root', 'totalProducts'] as const
const { classes } = extendableComponent(name, parts)

function SearchFormByNP(props: SearchFormProps) {
  const searchInputElement = useRef<HTMLInputElement>(null)
  const router = useRouter()

  useEffect(() => {
    if (!['/account/select-product'].includes(router.pathname)) {
      searchInputElement.current?.focus()
    }
  }, [])
  const { totalResults = 0, search = '', urlHandle = 'search', textFieldProps, sx = [] } = props

  const form = useForm({ mode: 'onChange', defaultValues: { search } })
  const { handleSubmit, formState, reset, watch, getValues, control } = form

  const submit = handleSubmit((formData) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.replace(`/${urlHandle}/${formData.search}`)
    reset(getValues())
  })
  useFormAutoSubmit({ form, submit })

  const handleReset = () => {
    reset({ search: '' })
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router
      .replace(`/${urlHandle}`)
      .then(() => document.getElementById('form-search-field')?.focus())
  }

  const endAdornment = !watch('search') ? (
    <IconButton size='small' aria-label={i18n._(/* i18n */ 'Search')}>
      <IconSvg src={iconSearch} />
    </IconButton>
  ) : (
    <>
      {totalResults > 0 && (
        <Box
          className={classes.totalProducts}
          sx={(theme) => ({
            minWidth: 'max-content',
            color: theme.palette.text.disabled,
            paddingRight: '7px',
          })}
        >
          {totalResults === 1 && <Trans id='{totalResults} result' values={{ totalResults }} />}
          {totalResults > 1 && <Trans id='{totalResults} results' values={{ totalResults }} />}
        </Box>
      )}
      <IconButton
        onClick={handleReset}
        size='small'
        sx={(theme) => ({
          color: theme.palette.filtoria.grey.darkest,
          backgroundColor: theme.palette.filtoria.grey.lighter,
          fontSize: '12px',
          padding: theme.spacing(0.5),
          marginRight: theme.spacing(1),
          position: 'relative',
        })}
      >
        <IconSvg src={iconClose} />
      </IconButton>
      <Box
        sx={(theme) => ({
          height: '50%',
          width: '1px',
          background: '#D8D6D4',
        })}
      >
        {' '}
      </Box>
      <IconButton size='small' aria-label={i18n._(/* i18n */ 'Search')}>
        <IconSvg src={iconSearch} />
      </IconButton>
    </>
  )

  return (
    <Box
      className={classes.root + ' search-form-inner'}
      component='form'
      noValidate
      onSubmit={submit}
      onChange={() => {
        if (watch('search') === '') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          router.replace(`/search`)
        }
      }}
      sx={sx}
    >
      <FormRow
        sx={{
          paddingTop: '2px',
        }}
        className='search-form-form-row'
      >
        <TextFieldElement
          variant='outlined'
          type='text'
          name='search'
          control={control}
          defaultValue={search}
          error={formState.isSubmitted && !!formState.errors.search}
          helperText={formState.isSubmitted && formState.errors.search?.message}
          InputProps={{ endAdornment }}
          inputRef={searchInputElement}
          {...textFieldProps}
        />
      </FormRow>
    </Box>
  )
}

function SearchFormPlugin(props: PluginProps<SearchFormProps>) {
  const { Prev, ...rest } = props
  return <SearchFormByNP {...rest} />
}

export const Plugin = SearchFormPlugin
