import View from '../View'
import { TextWrapper, Banner } from '../styledComponents'
import { useShowCookieConsent } from '../../util/useShowCC'

import { ICookieConsentProps } from '../globalTypes'
import { iconClose, IconSvg, Overlay } from '@graphcommerce/next-ui'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import IconArrowLeft from '../../../../components/Common/Icons/ArrowLeft/arrow-left-icon.svg'
import Link from 'next/link'
import { useRouter } from 'next/router'

const CookieBanner = (props: ICookieConsentProps) => {
  const { headingColor, paragraphColor, containerStyle } = props

  const [show, setShow] = useShowCookieConsent()
  const [consentOpen, setConsentOpen] = useState(false)
  const [showMangeView, setShowManageView] = useState<boolean>(false)
  const router = useRouter()

  const consentText = (
    <div style={{ fontSize: '16px', lineHeight: '24px' }}>
      <span
        dangerouslySetInnerHTML={{
          __html: `Vårt nettsted bruker informasjonskapsler for å forbedre din brukeropplevelse. Ved å klikke 'Godta' samtykker du til vår bruk av informasjonskapsler.<br />For mer informasjon, vennligst `,
        }}
      />
      <Link
        target={'_blank'}
        style={{ color: 'rgb(2, 97, 97)' }}
        // onClick={(e) => {
        //   e.preventDefault()
        //   router.push('/kundeservice/cookie-policy')
        //   setConsentOpen(false)
        // }}
        href='/kundeservice/cookie-policy'
      >
        klikk her.
      </Link>
    </div>
  )

  useEffect(() => {
    if (show && typeof window !== 'undefined') {
      setConsentOpen(true)
    }
  }, [show])

  // useEffect(() => {
  //   const hash = router.asPath.split('#')[1]
  //   if (hash && hash.includes('show-cookies-consent')) {
  //     setConsentOpen(true)
  //   }
  // }, [router])

  // if (!consentOpen) {
  //   return <div></div>
  // }

  return (
    <Overlay
      className={'cookies-consent-container w-md-600'}
      sx={{
        '& .header-wrap': {
          '& .MuiButtonBase-root': {
            top: {
              xs: '8px',
              md: '22px',
            },
            bottom: 0,
            minHeight: {
              xs: '52px',
              md: '40px',
            },
            width: {
              md: '40px',
            },
            height: {
              md: '40px',
            },
            marginRight: 0,
          },
        },
      }}
      variantMd={'right'}
      active={consentOpen}
      onClosed={() => setConsentOpen(false)}
    >
      <Box
        className='header-wrap'
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0',
          marginLeft: '26px',
          marginRight: '26px',
          height: {
            xs: '52px',
            md: '66px',
          },
          padding: 0,
          paddingTop: {
            xs: '8px',
            md: '8px',
          },
          boxSizing: 'content-box',
          position: 'relative',
          textAlign: 'center',
          borderBottom: '1px solid ' + theme.palette.filtoria.grey.lighter,
        })}
      >
        <IconButton
          onClick={() => {
            if (showMangeView) {
              setShowManageView(false)
              history.pushState({}, '', '/')
            }
          }}
          sx={(theme) => ({
            position: 'absolute',
            left: 0,
            top: theme.spacing(1),
            color: theme.palette.filtoria.grey.darkest,
            display: showMangeView ? 'show' : 'none',
          })}
        >
          <IconSvg src={IconArrowLeft} size='inherit' aria-hidden sx={{ fontSize: '24px' }} />
        </IconButton>
        <Typography
          sx={{
            marginTop: '15px',
            marginBottom: '15px',
          }}
          className='title'
          variant='h3'
          component='p'
        >
          Denne siden bruker cookies
        </Typography>
        <IconButton
          onClick={() => setConsentOpen(false)}
          sx={(theme) => ({
            position: 'absolute',
            right: 0,
            top: theme.spacing(1),
            color: theme.palette.filtoria.grey.darkest,
          })}
        >
          <IconSvg src={iconClose} size='inherit' aria-hidden sx={{ fontSize: '24px' }} />
        </IconButton>
      </Box>
      <Stack direction={'row'} alignItems={'center'}>
        <Banner style={containerStyle} show={show?.toString()}>
          <TextWrapper headingColor={headingColor} paragraphColor={paragraphColor}>
            <View
              {...props}
              setShow={setShow}
              type='banner'
              onAccept={() => setConsentOpen(false)}
              onDecline={() => setConsentOpen(false)}
              showMangeView={showMangeView}
              setShowManageView={setShowManageView}
              infoContent={consentText}
            />
          </TextWrapper>
        </Banner>
      </Stack>
    </Overlay>
  )
}

export default CookieBanner
