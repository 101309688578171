import Link from '@mui/material/Link'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { ContainerProps } from '@mui/material'
import { useState, useEffect } from 'react'
import { IconSvg, iconChevronUp, iconChevronDown } from '@graphcommerce/next-ui'

export type RowColunmLinkAutoItemProps = {
  item: any
  col: number
  mobile: boolean
} & Omit<ContainerProps, 'children'>

export function RowColunmAutoItem(props: RowColunmLinkAutoItemProps) {
  const { item, col, mobile } = props
  const [showList, setShowList] = useState(true)

  useEffect(() => {
    setShowList(!mobile)
  }, [mobile])

  const onClick = () => {
    if (mobile) {
      setShowList(!showList)
    }
  }

  return (
    <Grid
      item
      xs={col}
      md={1}
      sx={(theme) => ({
        paddingTop: '0px !important',
        paddingLeft: theme.spacings.xs,
        paddingRight: theme.spacings.xs,
      })}
    >
      <Box
        onClick={onClick}
        position={'relative'}
        sx={(theme) => ({
          paddingBottom: {
            xs: theme.spacings.xs,
            md: 0,
          },
          paddingTop: {
            xs: theme.spacings.xs,
            md: 0,
          },
          borderBottom: {
            xs: 1,
            md: 0,
          },
        })}
      >
        <Box>
          <b>{item.title}</b>
        </Box>
        <Box
          sx={(theme) => ({
            display: mobile ? 'block' : 'none',
            position: 'absolute',
            right: '0',
            top: theme.spacings.xs,
          })}
        >
          {showList ? <IconSvg src={iconChevronUp} /> : <IconSvg src={iconChevronDown} />}
        </Box>
      </Box>
      <Box>
        {item.links?.map((value, key) => (
          <Collapse key={key} in={showList}>
            <Box
              key={key}
              sx={(theme) => ({
                paddingTop: theme.spacings.xs,
              })}
            >
              <Link href={value.url}>{value.title}</Link>
            </Box>
          </Collapse>
        ))}
      </Box>
    </Grid>
  )
}
